import React from 'react';
import { useEffect } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Alert } from 'antd';
import { FieldValues, UseFormSetError } from 'react-hook-form';

type ErrorType = FetchBaseQueryError | SerializedError | GlobalError | undefined;

interface GlobalError {
  status: 400 | 401 | 403 | 404 | 500;
  data: {
    detail: string;
  };
}

// type guard to check if item is FetchBaseQueryError
const isFetchBaseQueryError = (error: ErrorType): error is FetchBaseQueryError => {
  return (
    (error as FetchBaseQueryError)?.status !== undefined &&
    (error as FetchBaseQueryError)?.data !== undefined &&
    (error as GlobalError)?.data?.detail === undefined
  );
};

// type guard to check if item is isGlobalError
const isGlobalError = (error: ErrorType): error is GlobalError => {
  return (error as GlobalError)?.data?.detail !== undefined;
};

export const useApiErrors = (
  error: ErrorType,
  setError: UseFormSetError<FieldValues>,
): React.FC | null => {
  useEffect(() => {
    if (isFetchBaseQueryError(error)) {
      const errorData = error.data || {};

      Object.entries(errorData).forEach(([key, value]) => {
        setError(key, {
          type: 'server',
          message: value[0] || 'Something went wrong',
        });
      });
    }
  }, [error, setError]);

  return isGlobalError(error)
    ? () => <Alert message={error.data.detail} type="error" showIcon />
    : null;
};
