import { ROUTES } from 'constants/routes';

import React from 'react';

import { BellOutlined } from '@ant-design/icons';
import { Button, Typography, Flex } from 'antd';
import {
  NOTIFICATION_PERMISSION_COLOR_MAPPING,
  NOTIFICATION_PERMISSION_TEXT_MAPPING,
  PUSH_FREQUENCY_TEXT_MAPPING,
  useGetMeQuery,
} from 'api/users';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectNotificationPermission } from 'store/appConfigSlice';
import styled from 'styled-components';

import Avatar from 'components/atoms/Avatar';
import CardTitle from 'components/atoms/CardTitle';
import TeamImage from 'components/atoms/TeamImage';
import { StyledCard } from 'components/atoms/styles';

const { Title } = Typography;

const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
`;

export const ColorWrapper = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

const AvatarWrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

const Raw = styled.div`
  margin-bottom: 8px;
`;

const UserDataDisplay = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard',
  });
  const navigate = useNavigate();

  const { data } = useGetMeQuery();
  const notificationPermission = useSelector(selectNotificationPermission) || 'default';

  const teamName = data?.team?.name;
  const teamImage = data?.team?.image;
  const avatar = data?.avatar;

  return (
    <StyledCard
      title={
        <Flex justify="space-between" align="center">
          <CardTitle>Profil</CardTitle>
          <Button onClick={() => navigate(ROUTES.PROFILE)}>Ustawienia</Button>
        </Flex>
      }
    >
      <Wrapper>
        <AvatarWrapper>
          <Avatar
            size={128}
            username={data?.username || ''}
            src={avatar}
            style={{ cursor: 'initial' }}
          />
          {!avatar && (
            <ButtonWrapper>
              <Button onClick={() => navigate(ROUTES.PROFILE)}>{t('addAvatar')}</Button>
            </ButtonWrapper>
          )}
        </AvatarWrapper>
        <div>
          <Raw>
            <Title level={5}>{data?.username}</Title>
          </Raw>

          {teamName && (
            <Raw>
              <Typography>
                {teamImage && <TeamImage size={16} src={teamImage} />}
                {data?.team?.name}
              </Typography>
            </Raw>
          )}
          <Raw>
            <Typography>
              <BellOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
              Powiadomienia push{' '}
              <ColorWrapper
                color={NOTIFICATION_PERMISSION_COLOR_MAPPING[notificationPermission]}
              >
                {NOTIFICATION_PERMISSION_TEXT_MAPPING[notificationPermission]}{' '}
              </ColorWrapper>
              {PUSH_FREQUENCY_TEXT_MAPPING[data?.push_frequency as 'daily']}
            </Typography>
          </Raw>
        </div>
      </Wrapper>
    </StyledCard>
  );
};

export default UserDataDisplay;
