import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

//Firebase Config values imported from .env file
const firebaseConfig = {
  apiKey: 'AIzaSyBR1n_6bPX50u-UA1Xr_w4VYoEKjm1h8GY',
  authDomain: 'typtyppl.firebaseapp.com',
  projectId: 'typtyppl',
  storageBucket: 'typtyppl.appspot.com',
  messagingSenderId: '1034053346012',
  appId: '1:1034053346012:web:bf96fe7c0fcb0a6e03ca19',
  measurementId: 'G-LG4JQ16V0C',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
