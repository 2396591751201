import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';

import { API_BASE_URL, API_CACHE_SECONDS } from '../constants';

import { Team } from './teams';
import { prepareHeaders } from './utils';

type pushFrequency = 'daily' | 'weekly' | 'monthly';

export interface UserApiResponse {
  username: string;
  email: string;
  date_birth: string;
  location: string;
  date_joined: string;
  team?: Team;
  avatar: string;
  push_frequency: pushFrequency;
}

export const PUSH_FREQUENCY_TEXT_MAPPING: Record<pushFrequency, string> = {
  daily: 'Codziennie',
  weekly: 'Raz w tygodniu',
  monthly: 'Miesięcznie',
};

export const NOTIFICATION_PERMISSION_TEXT_MAPPING: Record<
  NotificationPermission,
  string
> = {
  default: 'Nie wybrano',
  granted: 'Zezwolono',
  denied: 'Zablokowano',
};

export const NOTIFICATION_PERMISSION_COLOR_MAPPING: Record<
  NotificationPermission,
  string
> = {
  default: 'gray',
  granted: 'green',
  denied: 'red',
};

export interface CommentApiPayload {
  title: string;
  content: string;
}

export interface UserApiPayload {
  avatar: FileList;
  username: string;
  email: string;
  date_birth: string;
  team: string;
  date_joined: string;
  location: string;
}

type DeviceType = 'web' | 'mobile';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL + '/user',
    prepareHeaders,
  }),
  tagTypes: ['user'],
  keepUnusedDataFor: Number(API_CACHE_SECONDS),
  endpoints: (builder) => ({
    getMe: builder.query<UserApiResponse, void>({
      query: () => `/me/`,
      providesTags: ['user'],
    }),
    postComment: builder.mutation<UserApiResponse, CommentApiPayload>({
      query: (payload) => ({
        url: `/comment/`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateMe: builder.mutation<UserApiResponse, Partial<UserApiPayload>>({
      query: ({ avatar, ...payload }) => {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
          if (key === 'date_birth' && value) {
            formData.append('date_birth', dayjs(value).format('YYYY-MM-DDThh:mm'));
          } else {
            formData.append(key, value);
          }
        });

        if (avatar && avatar[0]) {
          formData.append('avatar', avatar[0]);
        }

        return {
          url: `/me/`,
          method: 'POST',
          formData: true,
          body: formData,
        };
      },
      invalidatesTags: ['user'],
    }),
    sendPushToken: builder.mutation<void, { token: string; deviceType: DeviceType }>({
      query: ({ token, deviceType }) => ({
        url: 'save-push-token/',
        method: 'POST',
        body: { token, device_type: deviceType },
      }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useUpdateMeMutation,
  usePostCommentMutation,
  useSendPushTokenMutation,
} = usersApi;
