import { useEffect } from 'react';

import { getToken } from 'firebase/messaging';

import { useSendPushTokenMutation } from '../api/users';
import { VAPID_KEY } from '../constants';
import { messaging } from '../firebase/firebaseConfig';
import { useAppDispatch } from '../store';
import { setNotificationsPermissionStatus } from '../store/appConfigSlice';

export function usePushPermission() {
  const [sendPushToken] = useSendPushTokenMutation();
  const dispatch = useAppDispatch();

  async function requestPermission() {
    const permission = await Notification.requestPermission();

    dispatch(setNotificationsPermissionStatus(permission));

    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY,
      });
      sendPushToken({ token, deviceType: 'web' });
      console.log('Token generated : ', token);
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);
}
