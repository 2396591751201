import { createSlice } from '@reduxjs/toolkit';

import { RootState } from './index';

interface AppConfig {
  notificationPermission?: NotificationPermission;
  isPWA?: boolean;
}

const initialState: AppConfig = {
  notificationPermission: 'default',
  isPWA: false,
};

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setNotificationsPermissionStatus: (state, { payload }) => {
      return { ...state, notificationPermission: payload };
    },
  },
});

export const { setNotificationsPermissionStatus } = appConfigSlice.actions;

export default appConfigSlice.reducer;

export const selectAppConfig = (state: RootState) => state.appConfig;
export const selectNotificationPermission = (state: RootState) =>
  state.appConfig?.notificationPermission;
