import React from 'react';

import placeholder from 'assets/placeholders/logoclub.svg';
import 'theme/sanitize.css';
import 'theme/fonts.css';
import styled from 'styled-components';

const Img = styled.img<{ $margin?: string }>`
  margin: ${({ $margin }) => $margin || '0 8px  0 0'};
`;

interface TeamImageProps {
  src?: string;
  size?: number;
  margin?: string;
}

const TeamImage: React.FC<TeamImageProps> = ({ src, size = 24, margin }) => {
  return <Img width={size} $margin={margin} src={src || placeholder} />;
};

export default TeamImage;
