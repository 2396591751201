import React, { useState } from 'react';

import { Alert, Button, Empty, Table, TableColumnsType, Typography } from 'antd';
import { useFetchLeagueWeekendMatchesToBetQuery, useSendBetMutation } from 'api/matches';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

import CustomInputNumber from 'components/atoms/InputNumber';
import { Spinner } from 'components/atoms/Spinner';
import StyledProgress from 'components/atoms/StyledProgress';
import { RowWrapper, TableWrapper } from 'components/atoms/Table';
import TeamImage from 'components/atoms/TeamImage';

import {
  ButtonWrapper,
  TableFooterWrapper,
  InputsWrapper,
  LeagueWrapper,
  SeparatorWrapper,
  FooterButtonWrapper,
  DateWrapper,
  TitleWrapper,
  NameWrapper,
} from './styles';
import { getFormMatchesWithBet, getMatchesWithBet } from './utils';

const { Text } = Typography;

interface DataType {
  key: React.Key;
  hostName: string;
  hostImage: string;
  guestName: string;
  guestImage: string;
  guestBetGoals: React.ReactNode;
  hostBetGoals: React.ReactNode;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Gospodarz - Gość',
    render: (record: DataType) => (
      <>
        <RowWrapper>
          <NameWrapper>
            <TeamImage margin={'0'} src={record.hostImage} />
            {record.hostName}
          </NameWrapper>
          <span>{record.hostBetGoals}</span>
        </RowWrapper>
        <RowWrapper>
          <NameWrapper>
            <TeamImage margin={'0'} src={record.guestImage} />
            {record.guestName}
          </NameWrapper>
          <span>{record.guestBetGoals}</span>
        </RowWrapper>
      </>
    ),
    responsive: ['xs'],
  },
  {
    title: 'Gospodarz',
    render: (record: DataType) => (
      <>
        {record.hostName} <TeamImage margin={'0 0 0 8px'} src={record.hostImage} />
      </>
    ),
    align: 'right',
    responsive: ['sm'],
  },
  {
    title: 'Twój typ',
    render: (record: DataType) => (
      <InputsWrapper>
        {record.hostBetGoals} <SeparatorWrapper>:</SeparatorWrapper>{' '}
        {record.guestBetGoals}
      </InputsWrapper>
    ),
    align: 'center',
    responsive: ['sm'],
  },
  {
    title: 'Gość',
    render: (record: DataType) => (
      <>
        <TeamImage src={record.guestImage} />
        {record.guestName}
      </>
    ),
    align: 'left',
    responsive: ['sm'],
  },
];

export const LeagueBet: React.FC<{ id: string }> = ({ id }) => {
  const [bet, setBet] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'bet',
  });

  const { data, isLoading, error } = useFetchLeagueWeekendMatchesToBetQuery({ id });

  const { control, handleSubmit, register, watch } = useForm();

  const matches = data?.matches || [];
  const weekNumber = data?.week_number || 0;
  const matchWeek: number = data?.match_week || 0;

  const handleBetStart = () => {
    setBet(true);
  };

  const [sendBet, { isLoading: isSendBetLoading, isError: isSendBetError }] =
    useSendBetMutation();

  const handleBetSend = async (data: FieldValues) => {
    await sendBet({
      match_week: matchWeek,
      week_number: weekNumber,
      matches: Object.values(data?.matches),
    }).then();
    setBet(false);
  };

  if (isLoading) {
    return <Spinner />;
  }

  // @ts-expect-error TODO: Fix
  if (error && error?.data?.details?.code === 100) {
    return (
      <Alert
        message={'Brak meczów do obstawienia'}
        description="Obstawianie meczy bedzie możliwe po ogłoszeniu terminarza spotkań"
        type="warning"
        showIcon
      />
    );
  }

  if (error || isSendBetError) {
    return (
      <Alert
        message={t('errorTitle')}
        description={t('errorDescription')}
        type="error"
        showIcon
      />
    );
  }

  const tableData: DataType[] = matches.map((match, index) => {
    return {
      key: match.id,

      // host
      hostName: match.host.name,
      hostImage: match.host.image,
      hostBetGoals: bet ? (
        <>
          <input
            type="hidden"
            {...register(`matches.${index}.match`)}
            defaultValue={match.id}
          />
          <CustomInputNumber
            name={`matches.${index}.host_goals`}
            control={control}
            min={0}
            max={99}
            defaultValue={match.bet[0]?.host_goals}
          />
        </>
      ) : (
        `${match.bet[0]?.host_goals ?? '-'}`
      ),

      // guest
      guestName: match.guest.name,
      guestImage: match.guest.image,
      guestBetGoals: bet ? (
        <>
          <input
            type="hidden"
            {...register(`matches.${index}.match`)}
            defaultValue={match.id}
          />
          <CustomInputNumber
            name={`matches.${index}.guest_goals`}
            control={control}
            min={0}
            max={99}
            defaultValue={match.bet[0]?.guest_goals}
          />
        </>
      ) : (
        `${match.bet[0]?.guest_goals ?? '-'}`
      ),
    };
  });

  return (
    <form onSubmit={handleSubmit((data) => handleBetSend(data))}>
      <LeagueWrapper>
        <TableWrapper data-testid={'betLeague'}>
          <Table
            size="middle"
            locale={{
              emptyText: <Empty description={t('noMatchesToBet')} />,
            }}
            style={{ width: '100%' }}
            title={() => (
              <>
                <ButtonWrapper>
                  <TitleWrapper level={4}>Kolejka: {weekNumber}</TitleWrapper>

                  {!bet && (
                    <Button
                      style={{ width: '120px' }}
                      data-testid={'startBet'}
                      type="primary"
                      onClick={handleBetStart}
                    >
                      {t('betMatch')}
                    </Button>
                  )}
                </ButtonWrapper>
                {!bet && (
                  <DateWrapper>
                    <Text>
                      {t('matchesDate')} {formatDate(data?.date_start)} -{' '}
                      {formatDate(data?.date_end)}
                    </Text>
                  </DateWrapper>
                )}
              </>
            )}
            pagination={false}
            footer={() => (
              <>
                <TableFooterWrapper>
                  <StyledProgress
                    percent={
                      bet
                        ? getFormMatchesWithBet(matches, watch)
                        : getMatchesWithBet(matches)
                    }
                  />
                </TableFooterWrapper>

                {bet && (
                  <FooterButtonWrapper>
                    <Button
                      style={{ width: '120px' }}
                      data-testid={'sendBet'}
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isSendBetLoading}
                    >
                      {t('sendBet')}
                    </Button>
                  </FooterButtonWrapper>
                )}
              </>
            )}
            columns={columns}
            dataSource={tableData}
          />
        </TableWrapper>
      </LeagueWrapper>
    </form>
  );
};

export default LeagueBet;
