import dayjs from 'dayjs';

import { DATE_FORMAT } from '../constants';

export const formatDate = (date?: string): string => {
  if (!date) {
    return '-';
  }

  return dayjs(date).format(DATE_FORMAT);
};
