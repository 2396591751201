import React, { useState } from 'react';

import { List, Button, Empty } from 'antd';
import {
  League,
  useAddUserLeagueMutation,
  useGetUserLeaguesQuery,
  useSearchLeaguesQuery,
  apiPageSize,
} from 'api/leagues';
import { useValidationRules } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CardTitle from 'components/atoms/CardTitle';
import InputSearch from 'components/atoms/InputSearch';
import { StyledCard } from 'components/atoms/styles';

import { ListEntry } from './styles';

interface LeagueSearchProps {
  tourRef?: React.RefObject<HTMLSpanElement>;
}

const LeagueSearch: React.FC<LeagueSearchProps> = ({ tourRef }) => {
  const [page, setPage] = useState(1);
  const [searchPhrase, setSearchPhrase] = useState('');

  const { t } = useTranslation('translation', {
    keyPrefix: 'leagueSearch',
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data: userLeagues } = useGetUserLeaguesQuery();
  const [addUserLeague] = useAddUserLeagueMutation();
  const { data: searchResults } = useSearchLeaguesQuery({ page, search: searchPhrase });
  const rules = useValidationRules();

  return (
    <StyledCard title={<CardTitle>{t('searchLabel')}</CardTitle>}>
      <span ref={tourRef}>
        <InputSearch
          name="search"
          size="large"
          help={t('searchHelp')}
          allowClear
          onSearch={(source) => {
            if (source === 'clear') {
              setPage(1);
              setSearchPhrase('');

              return;
            }
            handleSubmit(({ search }) => setSearchPhrase(search))();
          }}
          placeholder={t('searchPlaceholder')}
          control={control}
          errors={errors}
          rules={rules.search}
        />
      </span>
      <br />
      <List
        loading={false}
        locale={{
          emptyText: <Empty description={'Brak wyników'} />,
        }}
        data-testid="searchedLeagues"
        pagination={{
          showSizeChanger: false,
          pageSize: apiPageSize,
          position: 'bottom',
          current: page,
          total: searchResults?.count,
          onChange: (page) => {
            setPage(page);
          },
        }}
        dataSource={searchResults?.results}
        renderItem={(league: League, idx) => {
          const disabled = userLeagues?.some((userLeague) => userLeague.id === league.id);

          return (
            <List.Item>
              <List.Item.Meta
                title={
                  <>
                    <ListEntry>
                      {league.title}
                      <Button
                        disabled={disabled}
                        type="primary"
                        onClick={() => addUserLeague({ id: league.id })}
                      >
                        {t(disabled ? 'added' : 'add')}
                      </Button>
                    </ListEntry>
                  </>
                }
                description={
                  <div data-testid={`searchResult${idx}`}>
                    <p>{league.level}</p>
                    <p>{league.region}</p>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
    </StyledCard>
  );
};

export default LeagueSearch;
